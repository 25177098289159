.control-panel {
    position: absolute;
    display: flex;
    flex-direction: row;
    margin: 1rem;
    z-index: 1000;
}

.control-panel.expanded {
    background-color: #FFFAFA;
    color: black;
    border: 2px solid black;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: black 2px 2px;
}

.control-panel.expanded > .MuiSvgIcon-root {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.control-panel > .MuiSvgIcon-root:hover {
    position: absolute;
    top: calc(0.5rem-2.25px);
    right: calc(0.5rem-2.25px);
    cursor: pointer;
    border: 0.5px solid black;
    box-shadow: 5px 5px #666;
}

.feature-display {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
}

.feature-display span {
    margin: 0 1rem 1rem 1rem;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: white;
    border: 2px solid black;
    border-radius: 10px;
    width: 150px;
}

.control-panel canvas {
    width: 100px;
    height: 100px;
    border: 2px solid white;
}

.control-panel .canvas-container {
    padding: 0.5rem;
    background-color: black;    
    border: 2px solid black;
    border-radius: 10px;
}