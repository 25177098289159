/* Instruction text displayed on the drawing interface */
.instructions {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 30px;
    text-align: center;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    transition: 1s;
}