.welcome {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome > div {
    background-color: #FFFAFA;
    width: 95%;
    max-width: 1000px;
    color: black;
    border: 2px solid black;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: black 2px 2px;
}

.welcome p {
    margin: 1rem;
}